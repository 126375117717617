;(function($){

    function Courses() {
   	 	$(".courses article").hover(
			function(){
				//Ao posicionar o cursor sobre a div
				$("article img").css('filter','none');
				$("article img").css('-webkit-filter','grayscale(100%)');
				$("img",this).css('filter','gray');
				$("img",this).css('-webkit-filter','grayscale(0%)');
				
			},
			function(){
				//Ao remover o cursor da div
				$("img",this).css('filter','gray');
				 $("article img").css('-webkit-filter','grayscale(0%)');
			}
		);
    }

   	new Courses();

}(jQuery));
 