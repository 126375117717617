;(function($){

    function Slides() {
   	 	var _ =  this;

   	 	_.headerSlider();
      _.calendarSlider();
    }

    Slides.prototype.headerSlider =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.content-slider');

      

       _.elementPresentationSlider.slick({
        fade: true,
        arrows: true,
        dots: false,
        prevArrow:'<div class="slick-prev"></div>',
        nextArrow:'<div class="slick-next"></div>',
        autoplay: true,
        autoplaySpeed: 4000,
          responsive: [
          {
              breakpoint: 990,
              settings: {
                arrows:false,  
                dots:false
              }
            },
            {
              breakpoint: 640,
                settings: {
                  arrows:false,  
                  dots:false
                }
            }
          ]
  	   });
    };

    Slides.prototype.calendarSlider = function() {
        var _ = this;

       _.elementPresentationSlider = $('.slider-calendar .start');

      

       _.elementPresentationSlider.slick({
        fade: true,
        arrows: true,
        dots: false,
        prevArrow:$('.arrow-prev'),
        nextArrow:$('.arrow-next'),
          responsive: [
          {
              breakpoint: 990,
              settings: {
                arrows:false,  
                dots:false
              }
            },
            {
              breakpoint: 640,
                settings: {
                  arrows:false,  
                  dots:false
                }
            }
          ]
       });
    }
    
   	new Slides();

}(jQuery));
 
 
 
 
